<template>
	<div class="recruite">
		<logged-heard></logged-heard>
		<div class="recruite-center">
			<div class="center-current">
				<div class="current-input text-main">
					<div class="font-bold">企业基本信息</div>
					<div class="font-normal text-one">您所提供的信息将被保密，仅供认证审核使用</div>
					<div class="row-center">
						<div class="font-normal">企业logo：</div>
						<div class="logo-upload">
							<el-upload :id="getFuckId('logo', 1)" class="avatar-uploader" action="" :disabled="blurShow"
								:show-file-list="false" accept=".jpg,.png,.jpeg" :http-request="uploadMainImg">
								<img v-if="imageUrl" :src="imageUrl" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon">
									<div class="el-upload__text">上传图片</div>
								</i>
							</el-upload>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">企业全称：</div>
						<div class="input-div">
							<el-autocomplete :id="getFuckId('ent_name', 1)" class="inline-input"
								v-model="query.ent_name" :fetch-suggestions="querySearch"
								placeholder="请输入企业名称，须与企业证照保持一致" :trigger-on-focus="false" @select="handleSelect"
								@blur="blurNameClick">
							</el-autocomplete>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">企业简称：</div>
						<div class="input-div">
							<el-input placeholder="请输入企业简称" v-model="query.short_name" :disabled="blurShow" clearable>
							</el-input>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">企业性质：</div>
						<div class="input-div">
							<el-select :id="getFuckId('nature', 1)" v-model="query.nature" :disabled="blurShow"
								placeholder="请选择">
								<el-option v-for="item in nature" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">所属行业：</div>
						<div class="input-div">
							<el-select :id="getFuckId('industry', 1)" v-model="query.industry" :disabled="blurShow"
								placeholder="请选择">
								<el-option v-for="item in industry" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">企业地址：</div>
						<div class="input-div-one row">
							<div class="select-div">
								<el-cascader :id="getFuckId('addressValue', 1)" v-model="addressValue"
									:props="{ value: 'id' }" :options="addressList" :show-all-levels="false"
									@change="handleChange" :disabled="blurShow" ref="addressCascader"></el-cascader>
							</div>
							<div class="input-select-one one">
								<el-input :id="getFuckId('address', 1)" placeholder="请输入街道，楼宇位置" v-model="query.address"
									clearable :disabled="blurShow"></el-input>
							</div>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">人员规模：</div>
						<div class="input-div">
							<el-select :id="getFuckId('scale', 1)" v-model="query.scale" :disabled="blurShow"
								placeholder="请选择">
								<el-option v-for="item in scale" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">招聘联系人:</div>
						<div class="input-div input-one">
							<el-input :id="getFuckId('contacts', 1)" placeholder="请输入联系人" v-model="query.contacts"
								:disabled="blurShow" clearable>
							</el-input>
						</div>
					</div>
					<div class="recruite-input row-center">
						<div class="font-normal">企业福利：</div>
						<div class="input-div input-div-three">
							<el-input placeholder="请输入企业福利10字以内" v-model="welfare" :maxlength="10" clearable
								:disabled="blurShow"></el-input>
							<el-button type="primary" @click="addwelfare" :disabled="disabled">添加</el-button>
						</div>
					</div>
					<div style="margin-top: 20px;">
						<el-checkbox-group @change="chooseWel()" v-model="checkList">
							<div style="display: inline-block;margin-right: 5px;" class="font-normal pointer" v-for="(item, index) in query.welfare" :key="index">
								<el-checkbox :label="item" border></el-checkbox>
							</div>
						</el-checkbox-group>
					</div>
					<div class="row-end recruiter-button">
						<el-button type="primary" @click="subminClick">完成</el-button>
					</div>
				</div>
			</div>
		</div>
		<mask-model title="注册企业成功！" ref="mask" sureContent="去发布" :btnCancel="false" :recruiter="false" width="482px"
			@submitClick="submitClickType">
			<div class="mask-recruite font-normal">发布职位马上开启高效招聘</div>
		</mask-model>
		<feet-model></feet-model>
	</div>
</template>

<script>
	import loggedHeard from "../../components/loggedHeard.vue";
	import feetModel from "../../components/feet.vue";
	import maskModel from "../../components/mask.vue";
	import api from "../../api/tool";
	import apiRecruiter from "../../api/recruiter";
	import apiUser from "../../api/user";
	import entApi from "../../api/enterprises";
	export default {
		components: {
			loggedHeard,
			feetModel,
			maskModel
		},
		data() {
			return {
				// 企业地址
				addressList: [],
				// 企业性质
				nature: [],
				// 企业规模
				scale: [],
				// 行业类型
				industry: [],
				imageUrl: "",
				addressValue: [],
				query: {
					logo: "", //企业logo
					ent_name: "", //企业名称
					short_name: "", //企业简称
					nature: "", //企业性质
					scale: "", //企业规模
					province: "", //企业地址省
					city: "", //企业地址市
					address: "", //企业详细地址
					industry: "", //所属行业
					contacts: "", //联系人
					welfare: [], //企业福利
					province_id: "",
					city_id: ""
				},
				disabled: true,
				welfare: "",
				welfareiIndex: -1,
				blurShow: false,
				ent_name_one: "",
				checkList: []
			};
		},

		created() {
			this.getList();
			this.getAddress();
			this.getWelfare();
		},

		mounted() {},
		watch: {
			welfare(val) {
				if (val !== "") {
					this.disabled = false;
				} else {
					this.disabled = true;
				}
			},
		},
		methods: {
			chooseWel(event, index) {
				console.log(this.checkList)
			},
			handleSelect() {
				entApi.releasePosition({
					ent_name: this.query.ent_name
				}).then((res) => {
					if (res.code == 200) {
						if (res.data) {
							let data = res.data;
							this.imageUrl = data.logo_url;
							this.query.logo = data.logo;
							this.query.short_name = data.short_name;
							this.query.nature = data.nature;
							this.query.scale = data.scale;
							this.query.province = data.province;
							this.query.city = data.city;
							this.query.address = data.address;
							this.query.industry = data.industry;
							this.query.contacts = data.contacts;
							this.query.welfare = data.welfare;
							this.addressValue.push(data.province_id);
							this.addressValue.push(data.city_id);
							this.blurShow = true;
						} else {
							this.blurShow = false;
							this.addressValue = [];
							this.imageUrl = "";
							this.getWelfare();
							this.$util.objClear(this.query);
						}
					}
				});
			},
			querySearch(queryString, cb) {
				api.searchListByName({
					ent_name: queryString
				}).then((res) => {
					if (res.code == 200) {
						var restaurants = res.data.map((item) => {
							return {
								value: item.ent_name,
							};
						});
						var results = queryString ? restaurants.filter(this.createFilter(queryString)) :
							restaurants;
						// 调用 callback 返回建议列表的数据
						cb(results);
					}
				});
			},
			createFilter(queryString) {
				return (restaurant) => {
					return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
				};
			},
			// 获取企业详情
			blurNameClick() {
				if (this.blurShow) {
					this.ent_name_one = this.query.ent_name;
					this.blurShow = false;
					this.addressValue = [];
					this.imageUrl = "";
					this.getWelfare();
					this.$util.objClear(this.query);
					this.query.ent_name = this.ent_name_one
				}
			},
			// 获取企业福利
			getWelfare() {
				api.getWelfare().then((res) => {
					if (res.code == 200 && res.data.length > 0) {
						let welfare = res.data.map((item) => {
							return item.welfare_name;
						});
						this.query.welfare = welfare;
					}
				});
			},
			delClick(index) {
				if (this.blurShow) return;
				this.query.welfare.splice(index, 1);
			},
			addwelfare() {
				this.query.welfare.push(this.welfare);
				this.welfare = "";
			},
			// 获取列表数据
			getList() {
				api.getUserConfig().then((res) => {
					if (res.code == 200) {
						this.industry = res.data.industry.options;
						this.nature = res.data.nature.options;
						this.scale = res.data.scale.options;
					}
				});
			},
			// 获取企业地址
			getAddress() {
				api.getAddress().then((res) => {
					if (res.code == 200) {
						let region = res.data.children;
						region.forEach((element) => {
							element.children.forEach((item) => {
								item.children = "";
							});
						});
						this.addressList = region;
					}
				});
			},
			// 企业地址
			handleChange(e) {
				const obj = this.$refs.addressCascader.getCheckedNodes();
				this.query.province = obj[0].pathNodes[0].label;
				this.query.city = obj[0].label;
				this.query.province_id = e[0]
				this.query.city_id = e[1]
			},
			// hyz edit：获取焦点时的id
			getFuckId(type, index) {
				return type + '_' + index;
			},
			// 验证规则
			yzgz() {
				// hyz edit
				let dd = '';
				let item = this.query;
				if (!item.logo) dd = 'logo' + '_' + 1;
				else if (!item.ent_name) dd = 'ent_name' + '_' + 1;
				// else if(!item.short_name) dd = 'short_name'+'_'+1;
				else if (!item.nature) dd = 'nature' + '_' + 1;
				else if (!item.scale) dd = 'scale' + '_' + 1;
				// if(!item.addressValue) dd = 'addressValue'+'_'+1;
				else if (!item.address) dd = 'address' + '_' + 1;
				else if (!item.industry) dd = 'industry' + '_' + 1;
				else if (!item.contacts) dd = 'contacts' + '_' + 1;
				console.log('===dd===', item, dd)
				if (dd) {
					if (dd == 'logo_1') this.$util.msg("请选择企业LOGO", "error");
					else this.$util.msg("请填写完整信息", "warning");
					console.log('===dd===', dd)
					document.getElementById(dd).focus()
					return false;
				}

				// 老版规则
				if (this.query.ent_name == "") {
					this.$util.msg("请填写企业名称", "error");
					return false;
				} else if (this.query.nature == "") {
					this.$util.msg("请选择企业性质", "error");
					return false;
				} else if (this.query.scale == "") {
					this.$util.msg("请选择企业规模", "error");
					return false;
				} else if (this.addressValue == "") {
					this.$util.msg("请选择企业地址", "error");
					return false;
				} else if (this.query.address == "") {
					this.$util.msg("请填写详细地址", "error");
					return false;
				} else if (this.query.industry == "") {
					this.$util.msg("请选择所属行业", "error");
					return false;
				} else if (this.query.contacts == "") {
					this.$util.msg("请填写联系人", "error");
					return false;
				} else {
					return true;
				}
			},
			// 获取用户信息
			getUserInfo() {
				apiUser.userDetail().then((res) => {
					if (res.code == 200) {
						this.$store.commit("SET_USER", res.data);
					}
				});
			},
			// 提交
			subminClick() {
				// console.log(this.checkList);
				this.query.welfare = this.checkList;
				if (!this.yzgz()) return;
				const loading = this.$loading({
					lock: true,
					text: "提交中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				apiRecruiter.recruiter(this.query).then((res) => {
					loading.close();
					if (res.code == 200) {
						this.getUserInfo();
						this.$refs.mask.show();
					}
				});
			},
			// 跳转企业端发布职位
			submitClickType() {
				apiUser.toggleUser({
					user_type: 2
				}).then((res) => {
					this.$router.push({
						name: "position"
					});
				});
			},
			// 上传主图
			uploadMainImg(file) {
				if (this.blurShow) return;
				if (file.file.type != "image/png" && file.file.type != "image/jpg" && file.file.type != "image/jpeg") {
					this.$util.msg('请上传png,jpg,jpeg格式的图片', 'error')
					return
				}
				const isLt2M = file.file.size / 1024 / 1024 < 6;
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 6MB!");
					return;
				}
				// 创建一个formData对象实例的方式
				const data = new FormData();
				data.append("iFile", file.file);
				api
					.uploadImg(data)
					.then((res) => {
						// console.log(res);
						this.query.logo = res.data.file_id;
						this.imageUrl = res.data.file_path;
					})
					.catch((err) => {
						this.$message({
							showClose: true,
							message: "上传失败",
							type: "error",
						});
					});
			},
		},
	};
</script>
<style lang="less" scoped>
	.recruite {
		width: 100%;
		min-height: 100vh;
		background: #f6f7f8;

		.recruite-center {
			width: 100%;
			padding: 1.25rem 11.875rem 2.125rem 11.875rem;

			.center-current {
				width: 100%;
				background: #ffffff;
				padding-top: 2.1875rem;
				padding-bottom: 9.6875rem;
				box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);

				.current-input {
					width: 36.875rem;
					margin: 0 auto;

					.text-one {
						margin-top: 1.5rem;
						margin-bottom: 2.4375rem;
					}

					.logo-upload {
						margin-left: 1.375rem;

						/deep/ .el-upload {
							border: 1px dashed #e3e7ed;
							border-radius: 2px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
							width: 4.3125rem;
							height: 4.3125rem;
							display: flex;
							justify-content: space-evenly;
							align-items: center;
						}

						.el-upload__text {
							font-size: 12px;
							margin-top: 12px;
						}

						.avatar-uploader .el-upload:hover {
							border-color: #409eff;
						}

						.avatar-uploader-icon {
							font-size: 0.75rem;
							color: #8c939d;
						}

						.avatar {
							width: 4.3125rem;
							height: 4.3125rem;
							display: block;
						}
					}

					.recruite-input {
						margin-top: 1.8125rem;

						.input-div {
							margin-left: 1.375rem;
							width: 424px;

							/deep/.el-select {
								width: 100%;
							}

							/deep/.el-autocomplete {
								width: 100%;
							}
						}

						.input-one {
							margin-left: 18px;
						}

						.input-div-one {
							margin-left: 1.375rem;

							.select-div {
								width: 129px;

								/deep/.el-select {
									width: 100%;
								}
							}

							.input-select-one {
								width: 17.75rem;
								margin-left: 11px;
							}
						}

						.input-div-three {
							display: flex;
							justify-content: space-between;
							align-items: center;

							/deep/.el-input {
								width: 60%;
							}
						}
					}

					.welfare-list {
						margin-top: 1.8125rem;

						>div {
							width: 6.875rem;
							height: 2.0625rem;
							color: #ffffff;
							margin-right: 1.25rem;
							margin-bottom: 10px;
							position: relative;

							span {
								width: 100%;
								height: 100%;
								background: #126bf9;
							}

							.cha-posi {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;

								i {
									position: absolute;
									right: 6px;
									top: 4px;
								}
							}
						}
					}

					.recruiter-button {
						margin-top: 1.875rem;

						/deep/.el-button {
							padding: 12px 40px;
						}
					}
				}
			}
		}

		.mask-recruite {
			margin-top: 17px;
			margin-bottom: 31px;
		}
	}
</style>
